import axios from 'axios';
export default (data,advisor_id = null) => {
  const p = new Promise((resolve, reject) => {

  const requestData = {
    // "name": "Scott Conrad",
    // "phone": "9418947325",
    // "email": "scott@honopu.com",
    // "scheduling_preference": "",
    // "comments": "Hey Rod, Lead developer here. Just checking something out. Please ignore.",
    // "source_url": "https://www.hawaiilife.com/agents?page=1&island=&query=Easterly",
    // "source_type": "Advisor Index Page",
    // "source_description": "",
    // "advisor_id": 41
  };

// LeadSource__c:'HL Web - Scheduled Showing',
//           Showing_Request_Date__c:'2021-5-05T16:20:00-1000',
let debug = true;
  let adv_id = data.advisor_id ? data.advisor_id : advisor_id;
  const req = {
    name:data.name,
    phone:data.phone,
    email:data.email,
    comments:data.comments,
    source_url:data.source_url,
    source_type:data.source_type,
    source_description:data.source_description,
    preapprove:data.preapprove,
    advisor_id: adv_id
  } = data;

  if(!advisor_id) advisor_id = 829; //Customer Experience User

  if(!req.name || !req.phone || !req.email){
    reject("Missing required fields");
  }
  if(!req.comments){
    reject("Missing comments");
  }
  const config = {
    headers: {
      "accept": "application/json",
      "cache-control": "no-cache",
      "content-type": "application/json;charset=UTF-8",
      "pragma": "no-cache",
    }
  };



  axios.post("https://www.hawaiilife.com/api/v1/leads", req, config)
    .then(response => {
      console.log("Response:", response.data);
      resolve(response.data)
    })
    .catch(error => {
      reject( error);
    });
  });
  return p;
}

